import { Box, DialogProps, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../app/context';
import illustration from '../../assets/img/hello-2-illus.svg';
import { Button, Dialog, PremiumAvatar } from '../../components';

function WelcomeDialog({
  ...props
}: Omit<DialogProps, 'onClose'> & { onClose: () => void }): JSX.Element {
  const {
    partner: { getStartedProgression: getStartedAdminProgression, contract },
    user: { employee, getStartedProgression: getStartedEmployeeProgression },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="sm" {...props}>
      <Box display="flex" flexDirection="column" gap={3}>
        <img src={illustration} width={160} />
        <Typography fontSize="1.5rem" fontWeight={700}>
          {employee?.isAdminOrAnimator &&
          contract?.contractTemplate.code === 'geovelo-entreprise-free'
            ? "Bienvenue sur l'offre gratuite de Geovelo Entreprise 🎉"
            : 'Bienvenue sur Geovelo Entreprise 🎉'}
        </Typography>
        {employee?.isAdminOrAnimator &&
          contract?.contractTemplate.code === 'geovelo-entreprise-free' && (
            <Typography>
              Bénéficiez d'ores et déjà et de manière entièrement <b>gratuite</b> de ces services :
            </Typography>
          )}
        <Typography component="ul" display="flex" flexDirection="column" gap={2} paddingLeft={3}>
          {employee?.isAdminOrAnimator ? (
            <>
              <Item>
                Lancez des <b>challenges vélo</b> et suivez les classements
              </Item>
              <Item>
                Visualisez les <b>statistiques d'utilisation du vélo</b> pour l'ensemble de votre
                entreprise
              </Item>
              {employee.isAdmin && (
                <Item>
                  Envoyez à vos équipes un questionnaire personnalisé pour leur montrer les{' '}
                  <b>bénéfices de la pratique du vélo</b>
                </Item>
              )}
            </>
          ) : (
            <>
              <Item>Partipez à des challenges avec vos collègues</Item>
              <Item>Hissez-vous dans les tops des classements</Item>
              <Item>
                Suivez les statistiques de trajets vélo domicile-travail et celles de votre
                entreprise
              </Item>
            </>
          )}
        </Typography>
        <Box alignItems="center" display="flex" gap={3} justifyContent="flex-end">
          <Button
            color="primary"
            onClick={() => {
              props.onClose();
              if (
                employee?.isAdminOrAnimator
                  ? getStartedAdminProgression && !getStartedAdminProgression.allDone
                  : getStartedEmployeeProgression && !getStartedEmployeeProgression.allDone
              ) {
                navigate('/get-started');
              }
            }}
            variant="contained"
          >
            {t('companies.actions.here_we_go')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}

function Item({ isPremium, children }: { children: ReactNode; isPremium?: boolean }): JSX.Element {
  return (
    <li style={{ lineHeight: '24px' }}>
      {children}
      {isPremium && (
        <Box display="inline-block" marginLeft={1}>
          <PremiumAvatar />
        </Box>
      )}
    </li>
  );
}

export default WelcomeDialog;
