import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ["'Nunito Sans'", "'Roboto'", 'sans-serif'].join(', '),
  },
  palette: {
    primary: {
      main: '#326ac2',
    },
    secondary: {
      main: '#2ac682',
    },
    error: {
      main: '#A32828',
    },
  },
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          backgroundColor: '#cfcfcf',
        },
        bar: {
          borderRadius: '8px',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(15, 21, 33, 0.8)',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: (props) => props.className !== 'default',
              style: {
                '.MuiTableHead-root': {
                  backgroundColor: '#E3E7EE',
                  '& th': {
                    fontWeight: 700,
                    border: 'none',
                  },
                },
                '.MuiTableRow-root': {
                  border: 'none',
                  '&:nth-of-type(even)': {
                    backgroundColor: '#F6F7FB',
                  },
                  '& td': {
                    border: 'none',
                  },
                },
              },
            },
          ],
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

export const premiumTheme = createTheme({
  typography: {
    fontFamily: ["'Nunito Sans'", "'Roboto'", 'sans-serif'].join(', '),
  },
  palette: {
    primary: {
      main: '#6236BD',
    },
  },
});

export default theme;
