import { InfoOutlined, SvgIconComponent } from '@mui/icons-material';
import { Box, SvgIconProps, Typography } from '@mui/material';
import { ReactNode, useContext } from 'react';

import { AppContext } from '../app/context';

import { Card } from './card';
import { HelpIcon } from './icons';
import { PremiumBadge } from './premium-badge';

export function NewInfoCard({
  bgColor,
  row,
  variant,
  size,
  illustration,
  illustrationWidth,
  disableIcon,
  Icon,
  title,
  description,
  action,
}: {
  action?: ReactNode;
  bgColor?: string;
  description?: ReactNode;
  disableIcon?: boolean;
  Icon?: ((props: SvgIconProps) => JSX.Element) | SvgIconComponent;
  illustration?: string;
  illustrationWidth?: number;
  row?: boolean;
  size?: 'small';
  title?: string;
  variant?: 'try' | 'info' | 'warn' | 'success';
}): JSX.Element {
  const {
    partner: { contract },
  } = useContext(AppContext);

  return (
    <Card
      disableDivider
      sx={{
        border:
          variant === 'warn'
            ? '1px solid #FFE27D'
            : variant === 'success'
              ? '1px solid #96E2C1'
              : undefined,
        bgcolor:
          bgColor ||
          (variant === 'try'
            ? '#FFF5D0'
            : variant === 'info'
              ? '#F3F6FF'
              : variant === 'warn'
                ? '#FFF5D0'
                : variant === 'success'
                  ? '#ECFBF4'
                  : undefined),
        width: '100%',
      }}
    >
      <Box
        alignItems={row ? 'center' : 'flex-start'}
        display="flex"
        flexDirection="row"
        gap={2}
        padding={3}
        width="100%"
      >
        {illustration && (
          <img src={illustration} style={{ flexShrink: 0 }} width={illustrationWidth || 80} />
        )}
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection={row ? 'row' : 'column'}
          flexGrow={1}
          gap={2}
        >
          {variant === 'try'
            ? contract && <PremiumBadge contract={contract} />
            : variant === 'info'
              ? !Icon && !illustration && !disableIcon && <InfoOutlined color="primary" />
              : !description &&
                !illustration &&
                !disableIcon && <Avatar Icon={Icon} variant={variant} />}
          <Box
            alignItems={row ? 'center' : 'flex-start'}
            display="flex"
            flexDirection={row ? 'row' : 'column'}
            flexGrow={1}
            gap={2}
          >
            <Box
              display="flex"
              flexDirection={row || variant === 'info' || variant === 'success' ? 'row' : 'column'}
              flexGrow={row ? 1 : undefined}
              gap={(variant === 'info' && Icon) || (variant !== 'try' && description) ? 2 : 1}
            >
              {variant === 'info' || variant === 'success'
                ? !disableIcon && Icon && <Icon color={variant} />
                : variant !== 'try' &&
                  description &&
                  !illustration &&
                  !disableIcon && <Avatar Icon={Icon} variant={variant} />}
              {(title || description) && (
                <Box
                  alignSelf={row ? 'center' : undefined}
                  display="flex"
                  flexDirection="column"
                  flexGrow={row ? 1 : undefined}
                  gap={row ? 0 : 1}
                >
                  {title && (
                    <Typography
                      fontWeight={700}
                      variant={variant === 'try' ? 'h6' : size === 'small' ? 'body2' : 'body1'}
                    >
                      {title}
                    </Typography>
                  )}
                  {description && (
                    <Typography variant={size === 'small' ? 'body2' : 'body1'}>
                      {description}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
            {action && <Box flexShrink={0}>{action}</Box>}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

function Avatar({
  variant,
  Icon,
}: {
  Icon?: ((props: SvgIconProps) => JSX.Element) | SvgIconComponent;
  variant?: 'try' | 'info' | 'warn' | 'success';
}): JSX.Element {
  return (
    <Box
      alignItems="center"
      bgcolor={variant === 'warn' ? '#FFE27D' : variant === 'success' ? '#96E2C1' : '#FFF5D0'}
      borderRadius={5}
      color={variant === 'success' ? '#048760' : '#BB8A1A'}
      display="flex"
      flexShrink={0}
      height={40}
      justifyContent="center"
      width={40}
    >
      {Icon ? <Icon color="inherit" /> : <HelpIcon color="inherit" />}
    </Box>
  );
}
